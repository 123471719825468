import React from "react"
import Job from "./job"

type JobsProps = {
  jobs: Array<any>
}

const Jobs: React.FC<JobsProps> = ({ jobs }) => (
  <div className="w-full">
    {jobs.map((job: any, index: number) => (
      <div key={index} className="mb-8">
        <Job job={job} />
      </div>
    ))}
  </div>
)

export default Jobs
